<template>
  <div class="pay-success">
    <header class="df fdc jc-sb" v-if="order_type===1">
      <div class="df fdc alc">
        <div class="success-top">
          <icon-svg class="icon-white" icon-class="yi_icon_xuanze"/>
          {{seaStatus ?"兑换成功":"支付成功"}}
        </div>
        <!-- <template v-if="businessType == 2 && showWay == 1">
          <div class="success-bottom" v-if="money>0">实付{{(money * integralRate).toFixed(2)}}积分</div>
        </template>
        <template v-else>
          <div class="success-bottom" v-if="money>0">实付¥{{money}}</div>
        </template> -->
      </div>
      <div class="df fdr jc-sb">
        <router-link to="home" class="btn-item" replace>返回首页</router-link>
        <div class="btn-item" @click.stop="toOrderList">查看订单</div>
      </div>
    </header>
    <div class="success-head df fdc jc-sb" v-if="order_type===2">
      <div class="df fdc alc">
        <div class="success-top" v-if="group_buy_open_info.surplus_num>0">
          <icon-svg class="icon-white" icon-class="yi_icon_xuanze"/>
          支付成功，等待成团
        </div>
        <div class="success-top" v-if="group_buy_open_info.surplus_num==0">
          <icon-svg class="icon-white" icon-class="yi_icon_xuanze"/>
          支付成功，拼团成功
        </div>
        <!-- <template v-if="businessType == 2 && showWay == 1">
          <div class="success-bottom" v-if="money>0">实付{{(money * integralRate).toFixed(2)}}积分</div>
        </template>
        <template v-else>
          <div class="success-bottom" v-if="money>0">实付¥{{money}}</div>
        </template> -->
      </div>
      <div class="df fdr jc-sb p162">
        <router-link to="home" class="btn-item" replace>返回首页</router-link>
        <div class="btn-item" @click.stop="toOrderList">查看订单</div>
      </div>
      <div class="txt-center" v-if="group_buy_open_info.surplus_num>0">还差 {{group_buy_open_info.surplus_num}} 人拼团成功
      </div>
      <div class="txt-center" v-else>拼团成功</div>
      <div class="group-box df fdc alc jc-sb">
        <div class="group-list">
          <div class="group-item" v-for="(item,index) in people_limit" :key="index">
            <img
                v-if="index < groupList.length&&groupList[index].user_info.heagurl"
                class="line"
                :src="groupList[index].user_info.heagurl"
                alt
            >
            <img
                v-else-if="index < groupList.length&&!groupList[index].user_info.heagurl"
                class="line"
                src="../../assets/ic_head_default@3x.png"
                alt
            >
            <img v-else src="../../assets/ic_head_default@3x.png" alt>
            <div v-if="item.user_id === group_open_user_id" class="initiator">团长</div>
          </div>
        </div>
        <div class="cut-box" v-if="group_buy_open_info.surplus_num>0">
          剩余
          <yd-countdown
              :time="group_buy_open_info.end_time"
              done-text="00:00:00"
              timetype="timestamp"
              format="{%h}:{%m}:{%s}"
          >
            <span class="time">{%h}:{%m}:{%s}</span>
          </yd-countdown>
          <!--<span class="time">03:28:50</span> 失效-->
        </div>
        <div class="invite-btn" v-if="group_buy_open_info.surplus_num>0" @click="show_guide=true">邀请好友</div>
      </div>
    </div>
    <!--引导分享-->
    <div v-if="show_guide" class="guide-bg" @click="show_guide=false"></div>
    <div class="guide-box" v-if="show_guide">
      <div class="guide-top"></div>
      <div class="flex-center" @click="show_guide=false">
        <img class="guide-btn" src="../../assets/button_iknow@3x.png" alt>
      </div>
    </div>
    <!-- S 猜你喜欢 -->
    <recommend-list :itemList="recommendList" @click-item="clickItem"></recommend-list>
    <!-- E 猜你喜欢 -->
  </div>
</template>

<script>
import RecommendList from "@/components/recommend/RecommendList";
import {getItemList, getGroupUserList} from "@/services";
import {orderCollectDetail} from "@/services/orderApi.js";
import {baseUrl} from "../../utils/env";
import {wxShare}  from "../../utils/common";
import {mapState} from "vuex";

export default {
  name: "success",
  components: {
    RecommendList
  },
  data() {
    return {
      money: "", //
      order_type: 1, //订单类型 1 普通 2 拼团 3
      recommendList: [], // 猜你喜欢列表
      show_guide: false,
      group_id: "",
      group_open_user_id: "", // 开团人user_id
      item_info: {}, // 拼团商品信息
      sku_info: [], // 商品属性信息
      group_buy_open_info: {}, // 商品开团信息
      groupList: [], // 拼团成员列表
      countdown: 0, // 剩余时间
      people_limit: "", //开团人数
      pay_order_id: "",
      goods_info: "" //订单详情
    };
  },
  computed: {
    ...mapState({
      businessType: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      showWay: state => state.config.show_way, // 商品展示方式，1积分，2现金
      integralRate: state => state.config.integral_rate, // 积分比例
      seaStatus: state => state.seaStatus  //  海元素定制商城
    }),
  },
  methods: {
    getParams() {
      let group_id = this.$route.query.group_id;
      this.money = this.$route.query.money ? Number.parseFloat(this.$route.query.money) : 0;
      this.pay_order_id = this.$route.query.id ? this.$route.query.id : "";
      if (this.pay_order_id) {
        this.getDetail();
        if (group_id) {
          this.group_id = group_id;
          console.log("group_id" + group_id);
          this.getGroupList();
          this.order_type = 2;
        }
      } else {
        this.$router.go(-1);
      }
    },
    //路由改变jssdk参数重新赋值
    beforeRouteEnter(to, from, next) {
      next();
      wxShare();
    },
    /**
     * 订单信息
     * @returns {Promise<void>}
     */
    async getDetail() {
      try {
        const res = await orderCollectDetail(this.pay_order_id);
        if (res.code === 0) {
          const data = res.data;
          this.goods_info = data.item_list[0];
        }
        console.log(this.goods_info);
      } catch (err) {
        throw err;
      }
    },
    // 获取团购商品的开团用户列表
    async getGroupList() {
      try {
        const res = await getGroupUserList(this.group_id);
        if (res.code === 0) {
          const {
            user_id,
            group_buy_open_info,
            item_info,
            sku_info,
            group_buy_user_list
          } = res.data;
          this.group_open_user_id = user_id;
          this.people_limit = group_buy_open_info.people_limit;
          this.group_buy_open_info = group_buy_open_info;
          this.item_info = item_info;
          this.sku_info = sku_info;
          this.groupList = group_buy_user_list;
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (err) {
        throw err;
      }
    },
    // 获取猜你喜欢
    async getRecommendList() {
      try {
        let where = {};
        where.order = "sales";
        const res = await getItemList(where);
        if (res.code === 0) {
          const {data} = res.data;
          this.recommendList = [...this.recommendList, ...data];
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击商品
    clickItem(item) {
      this.$router.push({
        name: "goods-detail",
        query: {
          item_id: item.id
        }
      });
    },
    toOrderList() {
      const jump = 'order_list'
      this.$router.replace({
        name: jump,
        query: {
          key: 'ALL',
        },
      })
    }
  },
  mounted() {
    this.getRecommendList();
    this.getParams();
    document.title = "支付成功"
    let bid = window.localStorage.getItem("bid");
    let stateObject = {};
    let title = "title";
    let newUrl = `${baseUrl}/?bid=${bid}#/pay_success?id=${this.$route.query.id}&money=${this.$route.query.money}&group_id=${this.$route.query.group_id}`;
    history.replaceState(stateObject, title, newUrl);

  }
};
</script>

<style scoped lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.pay-success {
  .icon-white {
    color: @color-white;
    .wh(0.4rem, 0.4rem);
  }

  .success-top {
    .sc(0.44rem, @color-white);
    font-weight: 500;
    line-height: 0.6rem;
    margin-bottom: 0.04rem;
  }

  .success-bottom {
    opacity: 0.8;
  }

  .btn-item {
    .wh(1.84rem, 0.6rem);
    line-height: 0.6rem;
    text-align: center;
    border-radius: 0.44rem;
    border: 0.02rem solid @color-white;
  }

  header {
    height: 3rem;
    margin-bottom: 0.4rem;
    background: var(--main-color) url("../../assets/img_line@3x.png") no-repeat 0 0;
    background-size: 100% 0.96rem;
    padding: 0.5rem 1.62rem;
    .sc(0.28rem, @color-white);
  }

  /*拼团*/

  .success-head {
    height: 3.78rem;
    margin-bottom: 2.92rem;
    background: var(--main-color) url("../../assets/img_line@3x.png") no-repeat 0 0;
    background-size: 100% 0.96rem;
    padding: 0.86rem 0 0.48rem;
    position: relative;
    .sc(0.28rem, @color-white);

    .p162 {
      padding: 0 1.62rem;
    }

    .txt-center {
      text-align: center;
    }

    .group-box {
      .wh(7.3rem, 3.4rem);
      position: absolute;
      top: 3.24rem;
      left: 0;
      right: 0;
      margin: auto;
      background: url("../../assets/bg_group@3x.png") no-repeat 0 0;
      background-size: 7.3rem 3.4rem;
      padding: 0.88rem 0.84rem 0.44rem 0.86rem;

      .group-list {
        width: 100%;
        height: 1.5rem;
        white-space: nowrap;
        overflow-x: auto;

        .group-item {
          margin-right: 0.4rem;
          display: inline-block;

          &:last-child {
            margin-right: 0;
          }

          .line {
            border: 0.02rem solid var(--main-color);
          }

          .wh(0.8rem, 0.8rem);
          border-radius: 50%;
          position: relative;

          img {
            border-radius: 50%;
            .wh(100%, 100%);
          }

          .initiator {
            position: absolute;
            .wh(0.56rem, 0.28rem);
            .sc(0.2rem, @color-white);
            background: var(--main-color);
            line-height: 0.28rem;
            text-align: center;
            border-radius: 0.2rem;
            top: 0.64rem;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }

      .cut-box {
        .sc(0.22rem, @color-light-grey);
        line-height: 0.4rem;

        .time {
          .sc(0.28rem, var(--main-color));
          line-height: 0.32rem;
          font-weight: 500;
        }
      }

      .invite-btn {
        .wh(2.4rem, 0.6rem);
        line-height: 0.6rem;
        text-align: center;
        border-radius: 0.3rem;
        background: var(--main-color);
        .sc(0.24rem, @color-white);
      }
    }
  }

  /*引导分享*/

  .guide-bg {
    position: fixed;
    .wh(100%, 100%);
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 1;
  }

  .guide-box {
    position: fixed;
    .wh(100%, 100%);
    top: 0;
    left: 0;
    z-index: 2;

    .guide-top {
      .wh(4.5rem, 3.7rem);
      background: url("../../assets/img_share@3x.png") no-repeat;
      background-size: 100%;
      margin-left: 2.58rem;
      margin-bottom: 2.58rem;
    }

    .guide-btn {
      .wh(2.6rem, 0.9rem);
    }
  }
}
</style>
