var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pay-success" },
    [
      _vm.order_type === 1
        ? _c("header", { staticClass: "df fdc jc-sb" }, [
            _c("div", { staticClass: "df fdc alc" }, [
              _c(
                "div",
                { staticClass: "success-top" },
                [
                  _c("icon-svg", {
                    staticClass: "icon-white",
                    attrs: { "icon-class": "yi_icon_xuanze" },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.seaStatus ? "兑换成功" : "支付成功") +
                      "\n      "
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "df fdr jc-sb" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn-item",
                    attrs: { to: "home", replace: "" },
                  },
                  [_vm._v("返回首页")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn-item",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toOrderList.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("查看订单")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.order_type === 2
        ? _c("div", { staticClass: "success-head df fdc jc-sb" }, [
            _c("div", { staticClass: "df fdc alc" }, [
              _vm.group_buy_open_info.surplus_num > 0
                ? _c(
                    "div",
                    { staticClass: "success-top" },
                    [
                      _c("icon-svg", {
                        staticClass: "icon-white",
                        attrs: { "icon-class": "yi_icon_xuanze" },
                      }),
                      _vm._v("\n        支付成功，等待成团\n      "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.group_buy_open_info.surplus_num == 0
                ? _c(
                    "div",
                    { staticClass: "success-top" },
                    [
                      _c("icon-svg", {
                        staticClass: "icon-white",
                        attrs: { "icon-class": "yi_icon_xuanze" },
                      }),
                      _vm._v("\n        支付成功，拼团成功\n      "),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "df fdr jc-sb p162" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn-item",
                    attrs: { to: "home", replace: "" },
                  },
                  [_vm._v("返回首页")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn-item",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toOrderList.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("查看订单")]
                ),
              ],
              1
            ),
            _vm.group_buy_open_info.surplus_num > 0
              ? _c("div", { staticClass: "txt-center" }, [
                  _vm._v(
                    "还差 " +
                      _vm._s(_vm.group_buy_open_info.surplus_num) +
                      " 人拼团成功\n    "
                  ),
                ])
              : _c("div", { staticClass: "txt-center" }, [_vm._v("拼团成功")]),
            _c("div", { staticClass: "group-box df fdc alc jc-sb" }, [
              _c(
                "div",
                { staticClass: "group-list" },
                _vm._l(_vm.people_limit, function (item, index) {
                  return _c("div", { key: index, staticClass: "group-item" }, [
                    index < _vm.groupList.length &&
                    _vm.groupList[index].user_info.heagurl
                      ? _c("img", {
                          staticClass: "line",
                          attrs: {
                            src: _vm.groupList[index].user_info.heagurl,
                            alt: "",
                          },
                        })
                      : index < _vm.groupList.length &&
                        !_vm.groupList[index].user_info.heagurl
                      ? _c("img", {
                          staticClass: "line",
                          attrs: {
                            src: require("../../assets/ic_head_default@3x.png"),
                            alt: "",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("../../assets/ic_head_default@3x.png"),
                            alt: "",
                          },
                        }),
                    item.user_id === _vm.group_open_user_id
                      ? _c("div", { staticClass: "initiator" }, [
                          _vm._v("团长"),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
              _vm.group_buy_open_info.surplus_num > 0
                ? _c(
                    "div",
                    { staticClass: "cut-box" },
                    [
                      _vm._v("\n        剩余\n        "),
                      _c(
                        "yd-countdown",
                        {
                          attrs: {
                            time: _vm.group_buy_open_info.end_time,
                            "done-text": "00:00:00",
                            timetype: "timestamp",
                            format: "{%h}:{%m}:{%s}",
                          },
                        },
                        [
                          _c("span", { staticClass: "time" }, [
                            _vm._v("{%h}:{%m}:{%s}"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.group_buy_open_info.surplus_num > 0
                ? _c(
                    "div",
                    {
                      staticClass: "invite-btn",
                      on: {
                        click: function ($event) {
                          _vm.show_guide = true
                        },
                      },
                    },
                    [_vm._v("邀请好友")]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.show_guide
        ? _c("div", {
            staticClass: "guide-bg",
            on: {
              click: function ($event) {
                _vm.show_guide = false
              },
            },
          })
        : _vm._e(),
      _vm.show_guide
        ? _c("div", { staticClass: "guide-box" }, [
            _c("div", { staticClass: "guide-top" }),
            _c(
              "div",
              {
                staticClass: "flex-center",
                on: {
                  click: function ($event) {
                    _vm.show_guide = false
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "guide-btn",
                  attrs: {
                    src: require("../../assets/button_iknow@3x.png"),
                    alt: "",
                  },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _c("recommend-list", {
        attrs: { itemList: _vm.recommendList },
        on: { "click-item": _vm.clickItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }